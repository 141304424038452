import IndexEgePage from './index'
import { wrapper } from '../store/store'
import { getIndexPageProps } from './index/bll/getIndexPageProps'

IndexEgePage.getInitialProps = wrapper.getInitialPageProps(
  (store) =>
    async (ctx): Promise<any> => {
      await getIndexPageProps({ ctx, store })

      return {}
    }
)

export default IndexEgePage
