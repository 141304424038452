import { GetStaticPropsContext, NextPageContext } from 'next'
import { Store } from 'redux'
import { articlesThunks } from '@ucheba/store/articles'
import { tagsStatsThunks } from '@ucheba/store/tags/stats'
import { EProgramTypes } from '@ucheba/utils/types/program'
import { locationThunks } from '@ucheba/store/location'
import { institutionsRankingsStatsThunks } from '@ucheba/store/institutions/rankings/stats'
import { eventsSearchThunks } from '@ucheba/store/events/search'
import { sectionsEventsNavigationItems } from '../../for-abiturients/events/index/constants'
import { headerActions } from '../../../components/Header/store'
import { footerActions } from '../../../components/Footer/store'

type GetIndexPageProps = {
  ctx: NextPageContext | GetStaticPropsContext
  store: Store
}

type GetIndexPageReturn = Promise<{
  notFound?: boolean
  content?: {
    seo: {
      meta: {
        title: string
        description: string
        keywords: string
      }
    }
  }
}>

export const getIndexPageProps = async (
  props: GetIndexPageProps
): Promise<GetIndexPageReturn> => {
  const { ctx, store } = props
  const { dispatch } = store

  // programTypes для мероприятий на главной
  const programTypes = sectionsEventsNavigationItems
    .filter((_, key) => key !== 0)
    .map((el) => el.slug)

  await Promise.all([
    dispatch<any>(articlesThunks.fetch({ ctx, data: { limit: 10, isMain: true } })),
    dispatch<any>(
      tagsStatsThunks.fetchParents({
        ctx,
        data: {
          programTypes: [EProgramTypes.higher],
        },
      })
    ),
    dispatch<any>(locationThunks.fetch({ ctx, data: { isMain: 1, limit: 100 } })),
    dispatch<any>(institutionsRankingsStatsThunks.fetch({ ctx })),
    dispatch<any>(
      eventsSearchThunks.fetch({
        ctx,
        data: { limit: 5, programTypes, isFinished: 0 },
      })
    ),
  ])

  dispatch(headerActions.setColor('white'))
  dispatch(headerActions.setFade('navy'))
  dispatch(footerActions.setColor('black'))

  return {}
}
